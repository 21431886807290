<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Comisiones </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Comisiones de compras" icon="arrow-down">
        <ComissionList :allComissions="buyComissions"></ComissionList>
      </b-tab-item>
      <b-tab-item label="Comisiones de ventas" icon="arrow-up">
        <ComissionList :allComissions="sellComissions"></ComissionList>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import ComissionList from "../components/ComissionList";
// @ is an alias to /src
export default {
  name: "AllComissionsList",
  components: {
    ComissionList,
  },
  data() {
    return {};
  },
  methods: {},
  async created() {
    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETORGANIZATIONBOARD"));
    allPromises.push(this.$store.dispatch("GETCOMISSIONS", { type: 0 }));
    allPromises.push(this.$store.dispatch("GETCOMISSIONS", { type: 1 }));

    await Promise.all(allPromises);
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "comissions");
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return null;
    },
    buyComissions() {
      return this.$store.getters.BUYCOMISSIONS;
    },
    sellComissions() {
      return this.$store.getters.SELLCOMISSIONS;
    },
  },
};
</script>

<style scoped>
@import "../style/HumanResources.css";
@import "../../Global/style/Global.css";
</style>
