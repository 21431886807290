var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container global-list-main-container"},[_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v("Detalles de comisiones")]),_c('b-field',{staticClass:"employees-list-controls",attrs:{"grouped":"","group-multiline":""}}),_c('div',{staticClass:"global-lists-search"},[_c('div',{staticClass:"global-lists-filters-container"}),_c('div',{staticClass:"global-lists-filters-columns"},_vm._l((_vm.columns),function(singleColumn){return _c('b-checkbox',{key:singleColumn.label,model:{value:(singleColumn.display),callback:function ($$v) {_vm.$set(singleColumn, "display", $$v)},expression:"singleColumn.display"}},[_vm._v(" "+_vm._s(singleColumn.label)+" ")])}),1)]),_c('div',{staticClass:"table-header"},[_vm._v(" Cantidad de detalles de comisión: "),_c('b',[_vm._v(_vm._s(_vm.comissionsDetails.length))])]),_c('div',{staticClass:"global-list-main-container"},[_c('b-table',{ref:"comissionsDetails",attrs:{"pagination-position":"both","data":_vm.comissionsDetails,"striped":true,"bordered":true,"hoverable":true,"checked-rows":_vm.checkedComissions,"paginated":"","current-page":_vm.currentPage,"per-page":_vm.perPage,"opened-detailed":_vm.defaultOpenedDetails,"selected":_vm.selected,"detailed":"","detail-key":"NOMBRE","sort-icon":"arrow-up","sort-icon-size":"is-small","default-sort-direction":"asc","checkbox-position":"left","scrollable":"","default-sort":"NOMBRE"},on:{"update:checkedRows":function($event){_vm.checkedComissions=$event},"update:checked-rows":function($event){_vm.checkedComissions=$event},"update:selected":function($event){_vm.selected=$event},"details-open":function (row) { return _vm.$buefy.toast.open({
            message: ("Información de " + (row.NOMBRE)),
            type: 'is-success',
            position: 'is-bottom',
          }); }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_vm._v("No hay detalles de comisión")])]},proxy:true},{key:"default",fn:function(props){return _vm._l((_vm.columns),function(column){return _c('b-table-column',_vm._b({key:column.field,attrs:{"searchable":"","sortable":"","visible":column.display,"label":column.label,"custom-sort":column.customSort}},'b-table-column',column,false),[(!_vm.checkIfValid(props.row[column.field]))?_c('p',[_vm._v(" "+_vm._s("Sin información")+" ")]):_vm._e(),(
              _vm.checkIfValid(props.row[column.field]) &&
              !column.notPercentage &&
              !column.money
            )?_c('p',[_vm._v(" "+_vm._s(props.row[column.field])+" ")]):_vm._e(),(
              _vm.checkIfValid(props.row[column.field]) && column.notPercentage
            )?_c('p',[_vm._v(" "+_vm._s(props.row[column.field])+"% ")]):_vm._e(),(column.money && _vm.checkIfValid(props.row[column.field]))?_c('span',[_vm._v(_vm._s(_vm._f("money")(props.row[column.field],"MXN", 2)))]):_vm._e()])})}},{key:"detail",fn:function(props){return [_c('article',{staticClass:"media"},[_c('figure',{staticClass:"media-left"},[_c('p',{staticClass:"image is-64x64"})]),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"table-header providers-list-global table-header-border"},[_vm._v(" Cantidad de movimientos en historial: "),_c('b',[_vm._v(_vm._s(props.row.HISTORIAL.length))])]),_c('b-table',{ref:"retentionDetails",attrs:{"pagination-position":"both","data":props.row.HISTORIAL,"striped":true,"bordered":true,"hoverable":true,"sort-icon":"arrow-up","sort-icon-size":"is-small","default-sort-direction":"asc","checkbox-position":"left","scrollable":"","default-sort":"FECHA_FOR"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_vm._v(" No hay movimientos en historial ")])]},proxy:true},{key:"default",fn:function(props){return _vm._l((_vm.historyColumns),function(column){return _c('b-table-column',_vm._b({key:column.field,attrs:{"searchable":"","sortable":"","visible":column.display,"label":column.label,"custom-sort":column.customSort}},'b-table-column',column,false),[(!_vm.checkIfValid(props.row[column.field]))?_c('span',[_vm._v(_vm._s("Sin información"))]):_vm._e(),(column.money)?_c('span',[_vm._v(_vm._s(_vm._f("money")(props.row[column.field],"MXN", 2)))]):_vm._e(),(!column.money)?_c('span',[_vm._v(_vm._s(props.row[column.field]))]):_vm._e()])})}}],null,true)})],1)])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }