<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar incidencia</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado. </span> Las incidencias
      afectan la comisión ganada de los compradores y vendedores en el mes de la
      fecha seleccionada.
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Tipo (Requerido)"
        expanded
        label-position="inside"
        class="required-input"
        :type="{ 'is-danger': errors.TIPO }"
        :message="{
          'El tipo no es válido': errors.TIPO,
        }"
      >
        <b-select
          placeholder="Ejemplo: Vendedor"
          expanded
          icon="account-group-outline"
          v-model="newIncidence.TIPO"
          required
        >
          <option value="" disabled>Seleccione un tipo</option>
          <option value="VE">Ventas</option>
          <option value="CO">Compras</option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin" v-if="newIncidence.TIPO === 'CO'">
      <b-field
        label="Comprador (Opcional)"
        expanded
        label-position="inside"
        class="required-input"
        :type="{ 'is-danger': errors.EMPLEADO }"
        :message="{
          'El comprador no es válido': errors.EMPLEADO,
        }"
      >
        <b-select
          placeholder="Ejemplo: Warren Buffett"
          icon="account-tie"
          expanded
          v-model="newIncidence.EMPLEADO"
          required
        >
          <option value="" disabled>Seleccione un comprador</option>
          <option
            :value="singleEmployee._id"
            v-for="singleEmployee in buyers"
            :key="singleEmployee._id"
          >
            {{ singleEmployee.NOMBRE }} {{ " " }} {{ singleEmployee.PATERNO }}
            {{ " " }}
            {{ singleEmployee.MATERNO }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin" v-if="newIncidence.TIPO === 'VE'">
      <b-field
        label="Vendedor (Opcional)"
        expanded
        label-position="inside"
        class="required-input"
        :type="{ 'is-danger': errors.EMPLEADO }"
        :message="{
          'El vendedor no es válido': errors.EMPLEADO,
        }"
      >
        <b-select
          placeholder="Ejemplo: Warren Buffett"
          icon="account-tie"
          expanded
          v-model="newIncidence.EMPLEADO"
          required
        >
          <option value="" disabled>Seleccione un vendedor</option>
          <option
            :value="singleEmployee._id"
            v-for="singleEmployee in sellers"
            :key="singleEmployee._id"
          >
            {{ singleEmployee.NOMBRE }} {{ " " }} {{ singleEmployee.PATERNO }}
            {{ " " }}
            {{ singleEmployee.MATERNO }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Descripción (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': errors.DESCRIPCION }"
        :message="{
          'La descripción no es válida': errors.DESCRIPCION,
        }"
      >
        <b-input
          placeholder="Ejemplo: Descuento for falta"
          type="text"
          icon="clipboard"
          v-model="newIncidence.DESCRIPCION"
          required
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label-position="inside"
        label="Cantidad (Requerido)"
        expanded
        :type="{ 'is-danger': errors.CANTIDAD }"
        :message="{
          'La cantidad no es válida': errors.CANTIDAD,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 100"
          v-model="newIncidence.CANTIDAD"
          :required="true"
          :disabled="false"
          icon="cash"
        >
        </MoneyInput>
      </b-field>
      <b-field
        label="Fecha de incidencia (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': errors.FECHA }"
        :message="{
          'La fecha de incidencia no es válida': errors.FECHA,
        }"
        class="required-input"
      >
        <b-datepicker
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          v-model="newIncidence.FECHA"
          :show-week-number="true"
          locale="es-MX"
          placeholder="Ejemplo 20/10/2018"
          trap-focus
          icon="calendar-today"
          horizontal-time-picker
        />
      </b-field>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-success" @click="saveIncidence()"
        >Guardar incidencia</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import moment from "moment";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "AddIncidenceModal",
  props: [],
  directives: { cleave },
  components: {},
  data() {
    return {
      moment: moment,
      newIncidence: {
        DESCRIPCION: "",
        TIPO: "",
        FECHA: new Date(),
        CANTIDAD: 0,
        EMPLEADO: undefined,
      },
      masks: Masks,
      errors: {
        DESCRIPCION: false,
        TIPO: false,
        FECHA: false,
        cantidad: false,
        EMPLEADO: false,
      },
    };
  },
  async mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async saveIncidence() {
      try {
        this.errors = validateFields(this.newIncidence, this.errors);
        if (checkValidationErrors(this.errors)) {
          this.newIncidence.CANTIDAD *= -1;
          let response = await this.$store.dispatch(
            "SAVEINCIDENCE",
            this.newIncidence
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente la incidencia",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar la incidencia en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la incidencia en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar la incidencia en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {
    buyers() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        return singleEmployee;
      }).filter((singleEmployee) => {
        return singleEmployee.TIPO === "CO";
      });
    },
    sellers() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        return singleEmployee;
      }).filter((singleEmployee) => {
        return singleEmployee.TIPO === "VE";
      });
    },
  },
};
</script>

<style scoped>
@import "../style/AddEmployeeModal.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
@import "../../Configuration/style/Configuration.css";
@import "../../Global/style/Global.css";
</style>
