var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container add-global-modal-main-container"},[_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v("Agregar incidencia")]),_vm._m(0),_c('h3',{staticClass:"form-division"},[_vm._v("Información general")]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{staticClass:"required-input",attrs:{"label":"Tipo (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.errors.TIPO },"message":{
        'El tipo no es válido': _vm.errors.TIPO,
      }}},[_c('b-select',{attrs:{"placeholder":"Ejemplo: Vendedor","expanded":"","icon":"account-group-outline","required":""},model:{value:(_vm.newIncidence.TIPO),callback:function ($$v) {_vm.$set(_vm.newIncidence, "TIPO", $$v)},expression:"newIncidence.TIPO"}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Seleccione un tipo")]),_c('option',{attrs:{"value":"VE"}},[_vm._v("Ventas")]),_c('option',{attrs:{"value":"CO"}},[_vm._v("Compras")])])],1)],1),(_vm.newIncidence.TIPO === 'CO')?_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{staticClass:"required-input",attrs:{"label":"Comprador (Opcional)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.errors.EMPLEADO },"message":{
        'El comprador no es válido': _vm.errors.EMPLEADO,
      }}},[_c('b-select',{attrs:{"placeholder":"Ejemplo: Warren Buffett","icon":"account-tie","expanded":"","required":""},model:{value:(_vm.newIncidence.EMPLEADO),callback:function ($$v) {_vm.$set(_vm.newIncidence, "EMPLEADO", $$v)},expression:"newIncidence.EMPLEADO"}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Seleccione un comprador")]),_vm._l((_vm.buyers),function(singleEmployee){return _c('option',{key:singleEmployee._id,domProps:{"value":singleEmployee._id}},[_vm._v(" "+_vm._s(singleEmployee.NOMBRE)+" "+_vm._s(" ")+" "+_vm._s(singleEmployee.PATERNO)+" "+_vm._s(" ")+" "+_vm._s(singleEmployee.MATERNO)+" ")])})],2)],1)],1):_vm._e(),(_vm.newIncidence.TIPO === 'VE')?_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{staticClass:"required-input",attrs:{"label":"Vendedor (Opcional)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.errors.EMPLEADO },"message":{
        'El vendedor no es válido': _vm.errors.EMPLEADO,
      }}},[_c('b-select',{attrs:{"placeholder":"Ejemplo: Warren Buffett","icon":"account-tie","expanded":"","required":""},model:{value:(_vm.newIncidence.EMPLEADO),callback:function ($$v) {_vm.$set(_vm.newIncidence, "EMPLEADO", $$v)},expression:"newIncidence.EMPLEADO"}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Seleccione un vendedor")]),_vm._l((_vm.sellers),function(singleEmployee){return _c('option',{key:singleEmployee._id,domProps:{"value":singleEmployee._id}},[_vm._v(" "+_vm._s(singleEmployee.NOMBRE)+" "+_vm._s(" ")+" "+_vm._s(singleEmployee.PATERNO)+" "+_vm._s(" ")+" "+_vm._s(singleEmployee.MATERNO)+" ")])})],2)],1)],1):_vm._e(),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Descripción (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.errors.DESCRIPCION },"message":{
        'La descripción no es válida': _vm.errors.DESCRIPCION,
      }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: Descuento for falta","type":"text","icon":"clipboard","required":""},model:{value:(_vm.newIncidence.DESCRIPCION),callback:function ($$v) {_vm.$set(_vm.newIncidence, "DESCRIPCION", $$v)},expression:"newIncidence.DESCRIPCION"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label-position":"inside","label":"Cantidad (Requerido)","expanded":"","type":{ 'is-danger': _vm.errors.CANTIDAD },"message":{
        'La cantidad no es válida': _vm.errors.CANTIDAD,
      }}},[_c('MoneyInput',{attrs:{"currency":"MXN","digits":5,"placeholder":"Ejemplo: 100","required":true,"disabled":false,"icon":"cash"},model:{value:(_vm.newIncidence.CANTIDAD),callback:function ($$v) {_vm.$set(_vm.newIncidence, "CANTIDAD", $$v)},expression:"newIncidence.CANTIDAD"}})],1),_c('b-field',{staticClass:"required-input",attrs:{"label":"Fecha de incidencia (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.errors.FECHA },"message":{
        'La fecha de incidencia no es válida': _vm.errors.FECHA,
      }}},[_c('b-datepicker',{attrs:{"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre' ],"show-week-number":true,"locale":"es-MX","placeholder":"Ejemplo 20/10/2018","trap-focus":"","icon":"calendar-today","horizontal-time-picker":""},model:{value:(_vm.newIncidence.FECHA),callback:function ($$v) {_vm.$set(_vm.newIncidence, "FECHA", $$v)},expression:"newIncidence.FECHA"}})],1)],1),_c('div',{staticClass:"add-global-controls"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.saveIncidence()}}},[_vm._v("Guardar incidencia")]),_c('b-button',{staticClass:"cancel-button",attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Regresar")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"global-form-hint-container"},[_c('p',{staticClass:"global-form-hint"},[_vm._v("Los campos requeridos tienen el marco")]),_c('span',{staticClass:"global-form-hint-color"},[_vm._v(" Morado. ")]),_vm._v(" Las incidencias afectan la comisión ganada de los compradores y vendedores en el mes de la fecha seleccionada. ")])}]

export { render, staticRenderFns }