<template>
  <div class="container global-list-main-container">
    <h1 class="add-global-modal-main-title">Detalles de comisiones</h1>

    <b-field grouped group-multiline class="employees-list-controls"> </b-field>
    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>
    <div class="table-header">
      Cantidad de detalles de comisión: <b>{{ comissionsDetails.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="comissionsDetails"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="comissionsDetails"
        :checked-rows.sync="checkedComissions"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.NOMBRE}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="NOMBRE"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        default-sort="NOMBRE"
      >
        <template #empty>
          <div class="has-text-centered">No hay detalles de comisión</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
            :custom-sort="column.customSort"
          >
            <p v-if="!checkIfValid(props.row[column.field])">
              {{ "Sin información" }}
            </p>
            <p
              v-if="
                checkIfValid(props.row[column.field]) &&
                !column.notPercentage &&
                !column.money
              "
            >
              {{ props.row[column.field] }}
            </p>
            <p
              v-if="
                checkIfValid(props.row[column.field]) && column.notPercentage
              "
            >
              {{ props.row[column.field] }}%
            </p>
            <span
              v-if="column.money && checkIfValid(props.row[column.field])"
              >{{ props.row[column.field] | money("MXN", 2) }}</span
            >
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div
                  class="table-header providers-list-global table-header-border"
                >
                  Cantidad de movimientos en historial:
                  <b>{{ props.row.HISTORIAL.length }}</b>
                </div>
                <b-table
                  pagination-position="both"
                  :data="props.row.HISTORIAL"
                  :striped="true"
                  :bordered="true"
                  :hoverable="true"
                  ref="retentionDetails"
                  sort-icon="arrow-up"
                  sort-icon-size="is-small"
                  default-sort-direction="asc"
                  checkbox-position="left"
                  scrollable
                  default-sort="FECHA_FOR"
                >
                  <template #empty>
                    <div class="has-text-centered">
                      No hay movimientos en historial
                    </div>
                  </template>

                  <template slot-scope="props">
                    <b-table-column
                      v-for="column in historyColumns"
                      searchable
                      sortable
                      v-bind="column"
                      :key="column.field"
                      :visible="column.display"
                      :label="column.label"
                      :custom-sort="column.customSort"
                    >
                      <span v-if="!checkIfValid(props.row[column.field])">{{
                        "Sin información"
                      }}</span>
                      <span v-if="column.money">{{
                        props.row[column.field] | money("MXN", 2)
                      }}</span>
                      <span v-if="!column.money">{{
                        props.row[column.field]
                      }}</span>
                    </b-table-column>
                  </template>
                </b-table>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";

export default {
  name: "ComissionsDetailsList",
  components: {},
  props: ["comissions"],
  data() {
    //Proveedores
    return {
      perPage: 50,
      checkedComissions: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "NOMBRE",
          label: "Nombre",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "PATERNO",
          label: "Apelido paterno",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "MATERNO",
          label: "Apellido materno",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "TOTAL",
          label: "Total",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "SALARIO_PRESTAMO_FOR",
          label: "Salario fijo es un prestamo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "SALARIO_FOR",
          label: "Salario final",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
      ],
      historyColumns: [
        {
          field: "FECHA_FOR",
          label: "Fecha",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDate,
        },
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CANTIDAD",
          label: "Cantidad",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
      ],
    };
  },
  methods: {
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    comissionsDetails() {
      return this.comissions.map((singleComission) => {
        const singleComissionWithProps = { ...singleComission };

        for (const [
          singleHistoryIndex,
          singleHistory,
        ] of singleComissionWithProps.HISTORIAL.entries()) {
          singleComissionWithProps.HISTORIAL[singleHistoryIndex].FECHA_FOR =
            moment(singleHistory.FECHA).format("DD-MM-YYYY HH:mm");
        }

        singleComissionWithProps.SALARIO_PRESTAMO_FOR =
          singleComissionWithProps.SALARIO_PRESTAMO === "S" ? "Si" : "No";

        singleComissionWithProps.SALARIO_FOR =
          singleComissionWithProps.SALARIO_PRESTAMO === "S"
            ? singleComissionWithProps.TOTAL - singleComissionWithProps.SALARIO
            : singleComissionWithProps.TOTAL + singleComissionWithProps.SALARIO;

        return singleComissionWithProps;
      });
    },
  },
  created() {
    moment.locale("es");
  },
};
</script>

<style scoped>
@import "../style/EmployeesList.css";
@import "../../Global/style/Global.css";
</style>
