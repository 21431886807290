<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="employees-list-controls">
      <div class="control">
        <b-button type="is-primary" @click="openAddIncidence()"
          >Agregar incidencia</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="refreshInfo()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>
    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>
    <div class="table-header">
      Cantidad de comisiones mensuales: <b>{{ comissions.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="comissions"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="comissions"
        :checked-rows.sync="checkedComissions"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.FECHA_INICIO_FOR}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="FECHA_INICIO_FOR"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        default-sort="FECHA_INICIO_FOR"
      >
        <template #empty>
          <div class="has-text-centered">No hay comisiones mensuales</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
            :custom-sort="column.customSort"
          >
            <p v-if="!checkIfValid(props.row[column.field])">
              {{ "Sin información" }}
            </p>
            <p
              v-if="
                checkIfValid(props.row[column.field]) &&
                !column.notPercentage &&
                !column.money
              "
            >
              {{ props.row[column.field] }}
            </p>
            <p
              v-if="
                checkIfValid(props.row[column.field]) && column.notPercentage
              "
            >
              {{ props.row[column.field] }}%
            </p>
            <span
              v-if="column.money && checkIfValid(props.row[column.field])"
              >{{ props.row[column.field] | money("MXN", 2) }}</span
            >
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <b-button
                  type="is-primary"
                  @click="openComissionDetails(props.row)"
                  >Ver detalles de comisión</b-button
                >
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import ComissionsDetailsList from "./ComissionsDetailsList";
import AddIncidenceModal from "./AddIncidenceModal";

export default {
  name: "ComissionList",
  components: {},
  props: ["allComissions"],
  data() {
    //Proveedores
    return {
      perPage: 50,
      checkedComissions: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "FECHA_INICIO_FOR",
          label: "Fecha de inicio",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDateStart,
        },
        {
          field: "FECHA_FIN_FOR",
          label: "Fecha final",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDateEnd,
        },
        {
          field: "TOTAL",
          label: "Total",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
      ],
      refreshLoading: false,
    };
  },
  methods: {
    async refreshInfo() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETCOMISSIONS", { type: 0 }));
      allPromises.push(this.$store.dispatch("GETCOMISSIONS", { type: 1 }));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    sortByDateStart(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_INICIO).getTime() || -Infinity) -
          (new Date(a.FECHA_INICIO).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_INICIO).getTime() || -Infinity) -
          (new Date(b.FECHA_INICIO).getTime() || -Infinity)
        );
      }
    },
    sortByDateEnd(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_FIN).getTime() || -Infinity) -
          (new Date(a.FECHA_FIN).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_FIN).getTime() || -Infinity) -
          (new Date(b.FECHA_FIN).getTime() || -Infinity)
        );
      }
    },
    openComissionDetails(comissionRow) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ComissionsDetailsList,
        props: {
          comissions: comissionRow.COMISIONES,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddIncidence() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddIncidenceModal,
        props: {},
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    comissions() {
      return this.allComissions.map((singleComission) => {
        const singleComissionWithProps = { ...singleComission };

        singleComissionWithProps.FECHA_INICIO_FOR = moment(
          singleComission.FECHA_INICIO
        ).format("DD-MM-YYYY HH:mm");

        singleComissionWithProps.FECHA_FIN_FOR = moment(
          singleComission.FECHA_FIN
        ).format("DD-MM-YYYY HH:mm");

        return singleComissionWithProps;
      });
    },
  },
  created() {
    moment.locale("es");
  },
};
</script>

<style scoped>
@import "../style/EmployeesList.css";
@import "../../Global/style/Global.css";
</style>
